
        @import "@/styles/griddle-overrides.scss";
        @import "@braid/griddle/scss/griddle.scss";
        






































.kpis {
  margin-bottom: 1.5rem;
}

.kpis__list {
  display: grid;
  grid-gap: 0.25rem;

  @media screen and (min-width: bp(m)) {
    grid-template-columns: repeat(3, minmax(15rem, 1fr));
  }
}
