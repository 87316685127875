
        @import "@/styles/griddle-overrides.scss";
        @import "@braid/griddle/scss/griddle.scss";
        














































@import '@/styles/_colors.scss';

.kpi {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 8rem;
  padding: 1rem;
  overflow: hidden;
  color: var(--color-text-secondary);
  font-weight: 500;
  text-decoration: none;
  background: var(--color-primary);

  &:hover {
    background: var(--color-primary-dark);
  }

  &.disabled {
    opacity: 0.6;
  }
}

.kpi--empty {
  color: rgba($color-purple, 0.25);
  background: rgba($color-grey-100, 0.5);

  &:hover {
    background: rgba($color-grey-100, 0.4);
  }

  & > .kpi__icon {
    opacity: 0.25;
  }
}

.kpi__value {
  font-weight: 900;
  font-size: 3rem;
  line-height: 1em;
}

.kpi__icon {
  position: absolute;
  right: -1rem;
  bottom: -1rem;
  font-size: 8rem;
  opacity: 0.15;
}
